/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import wrapWithProvider from "./wrap-with-provider";
import "./src/theme/reset.css";
export const wrapRootElement = wrapWithProvider;

export const onServiceWorkerUpdateReady = () => {
  window.location.reload();
};
