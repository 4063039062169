const baseItem = {
  color: "white",
  borderColor: "primaryLight",
  borderWidth: "1px",
  borderRadius: 0,
  "::placeholder": {
    color: "white"
  },
  "&:focus": {
    borderColor: "white"
  }
};

const forms = {
  input: baseItem,
  select: baseItem,
  textarea: baseItem,
  text: { color: "text" },
  label: { pb: 2 },
  radio: {},
  checkbox: {},
  file: {}
};

export default forms;
