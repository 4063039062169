const buttons = {
  primary: {
    fontSize: "body",
    fontWeight: "bold",
    color: "text",
    bg: "transparent",
    borderRadius: "0",
    cursor: "pointer",
    transition: "all 300ms ease",
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "gray30",
    px: 6,
    pt: 1,
    "i": {
      fontSize: 5,
      ":before": {
        marginRight: 0,
        marginLeft: 1,
        fontSize:"100%",
        transform: "translate(2px,2px)",
        transition: "transform 200ms ease"
      },
    },
    "&:hover": {      
     borderColor: "gray",
     "i": {
       color: "primary",
       "&:before": {
        transform: "translate(10px,2px)"
       }
     }
    }
  },
  secondary: {
    variant: "buttons.primary",
    color: "white",
    borderColor: "primaryLight",
    "&:hover": {      
      borderColor: "white",
      "i": {
        color: "white",
        "&:before": {
          transform: "translate(10px,2px)"
         }
      }
    }
  },
  transparent: {
    variant: "buttons.primary",
    transition: "all 300ms ease",
    color: "inherit",
    bg: "transparent",
    "&:hover": { bg: "transparent" }
  },
  white: {
    variant: "buttons.primary",
    transition: "all 300ms ease",
    color: "primary",
    bg: "white",
    "&:hover": { bg: "primary", color: "white" }
  },
  ninja: {
    cursor: "pointer",
    color: "inherit",
    bg: "transparent",
    m: 0,
    p: 0,
    border: "none",
    "&:hover": {
      bg: "transparent",
      opacity: 1
    }
  }
};

export default buttons;
