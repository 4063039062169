import colors from "./colors";
import buttons from "./buttons";
import variants from "./variants";
import forms from "./forms";
import { theme as themeSettings } from "../cms/data/settings";

const theme = {
  colors: colors,
  forms: forms,
  fonts: {
    body: themeSettings.font.fontname,
    heading: themeSettings.fontheads.fontname,
    monospace: "Menlo, monospace"
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 28, 32, 48, 56, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1
  },
  space: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 100, 120, 256, 512],
  sizes: {
    avatar: 48
  },
  radii: {
    square: 0,
    default: 4,
    circle: 99999
  },
  shadows: {
    card: "0 0 4px rgba(0, 0, 0, .125)"
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: [8,8,9],
      color: "text",
      mb: [5,5,6],
      display: "inline-flex",
      pl: [3,3,4],
      borderLeftStyle: "solid",
      borderLeftColor: "primary",
      borderLeftWidth: "10px"
    },
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      fontSize: [9, 9, 10],
      color: "white",
      mb: [6,6,7]
    },
    h3: {
      fontFamily: "body",
      fontWeight: "normal",
      fontSize: [5,5,6],
      mb: [5,5,6],
      pl: 2,
      borderLeftStyle: "solid",
      borderLeftColor: "primary",
      borderLeftWidth: "3px"
    },
    titleModal: {
      variant: "text.heading",
      fontSize: [5,6,7,8]
    },
    display: {
      fontFamily: "Work Sans",
      fontWeight: "heading",
      lineHeight: "heading",
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
      fontSize: "inherit"
    }
  },
  variants: variants,
  buttons: buttons,
  breakpoints: Object.values(themeSettings.breakpoints),
  gutter: 30
};
export default theme;
