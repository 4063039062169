import colors from "./colors";
const variants = {
  avatar: {
    width: "avatar",
    height: "avatar",
    borderRadius: "circle"
  },
  cookiesBanner: {
    position: "fixed",
    bottom: "0",
    left: 0,
    right: 0,
    zIndex: 99,
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)"
  },
  categorieCircle: {
    display: "inline-block",
    margin: "0 auto",
    bg: "primary",
    width: "48px",
    height: "48px",
    borderRadius: 9999,
    color: "#fff"
  },
  badges: {
    display: "inline-block",
    px: 2,
    py: 1,
    borderRadius: 9999,
    fontSize: 0
  },
  badge: {
    primary: {
      variant: "variants.badges",
      color: "white",
      bg: "primary"
    },
    primaryGradiant: {
      variant: "variants.badges",
      color: "white",
      bg: "primary",
      background: colors.gradiantPrimary
    },
    gradiantText: {
      variant: "variants.badges",
      color: "white",
      bg: "text",
      background: colors.gradiantText
    },
    gradiantPurple: {
      variant: "variants.badges",
      color: "white",
      bg: "purple",
      background: colors.gradiantPurple
    },
    offre47: {
      variant: "variants.badges",
      color: "white",
      bg: "purple",
      background: colors.gradiantPurple
    },
    offre48: {
      variant: "variants.badges",
      color: "white",
      bg: "primary",
      background: colors.gradiantPrimary
    },
    offre180: {
      variant: "variants.badges",
      color: "white",
      bg: "goldDark",
      background: colors.gradiantGold
    }
  },
  card: {
    p: 2,
    bg: "background",
    boxShadow: "card"
  },
  link: {
    color: "inherit",
    textDecoration: "none",
    fontWeight: "inherit"
  },
  linktext: {
    color: "inherit",
    textDecoration: "inherit"
  },
  darkBg: {
    backgroundColor: "primary",
    color: "background"
  },
  bgImg: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  squareBox: {
    position: "relative",
    "&:after": {
      content: "''",
      display: "block",
      paddingBottom: "100%"
    }
  },
  nav: {
    fontSize: 1,
    fontWeight: "bold",
    display: "inline-block",
    p: 2,
    color: "inherit",
    textDecoration: "none",
    ":hover,:focus,.active": {
      color: "primary"
    }
  },
  searchBar: {
    backgroundColor: "primary",
    background: colors.gradiantPrimary
  },
  navbar: {
    width: ["100%"],
    transition: "all 300ms ease",
    minHeight: ["70px"],
    backgroundColor: "transparent",
    color: "#FFF",
    a: {
      color: "#FFF"
    },
    ".logo": {
      display: "block",
      lineHeight: 0
    },
    position: "absolute",
    zIndex: 10,
    "&.sticky": {
      backgroundColor: "background",
      boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.4)",
      color: "text",
      a: {
        color: "text"
      }
    }
  },
  mainMenuItem: {
    a: {
      position: "relative",
      ":after": {
        content: "''",
        display: "block",
        height: "3px",
        backgroundColor: "primary",
        position: "absolute",
        left: "0",
        bottom: "0",
        width: "0",
        bottom: "-10px",
        transition: "width 200ms"
      },
      "&.active": {
        ":after": {
          width: "100%"
        }
      },
      "&:hover": {
        color: "primary"
      }
    }
  },
  container: {
    width: "100vw",
    maxWidth: ["100%", "100%", "960px", "1200px"],
    m: "0 auto",
    flexbox: true,
    px: [2, 2, 3]
  },
  containerFluid: {
    width: "100%",
    m: "0 auto",
    flexbox: true,
    px: [2, 2, 5]
  },
  grid: {
    flexWrap: "wrap",
    mx: [-2, -2, -3],
    "> div": {
      p: [2, 2, 3]
    }
  },
  gridItem: {
    px: [2, 2, 3]
  },
  section: {
    width: "100%",
    pt: [10, 10, 11],
    pb: [10, 10, 11]
  },
  titleFooter: {
    fontWeight: "bold",
    color: "primary"
  }
};

export default variants;
