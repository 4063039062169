import styled, { createGlobalStyle, css } from "styled-components";
import { width, height, color, space, boxShadow, borderRadius } from "styled-system";
import { themeGet } from "@styled-system/theme-get";


const icofontCss = css`
  ${import("../components/UI/Icofont/icofont/icofont.min.css")}
`;
const fontelloCss = css`
  ${import("../components/UI/Fontello/fontello/css/fontello.css")}
`;

const GlobalStyle = createGlobalStyle`

${icofontCss}
.drawer-content{
  background-color:${themeGet("colors.background")};
  color: ${themeGet("colors.text")};
}
body {
  background-color:${themeGet("colors.background")};
  font-family: ${themeGet("fonts.body")};
  line-height: ${themeGet("lineHeights.body")};
  color: ${themeGet("colors.text")};
  font-size:${themeGet("fontSizes.2")}px;
  margin: 0;
}
*{
  font-family: ${themeGet("fonts.body")};
  &:focus {
  outline: none;
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0px;
}
ul,ol {
  list-style: none;
  > li {
    display: flex;
    align-items: center;
    padding-bottom: ${themeGet("space.1")}px;
    &:before {
      display: block;
      color: ${themeGet("colors.primary")};
      margin-right: ${themeGet("space.3")}px;
    }
  }
}
ul > li:before {
  content: "■";
  font-weight: bold;
}
ol {
  counter-reset: li;
  li {
    counter-increment: li;
    &:before {
      content: counter(li);
      text-align: right;
      font-size: ${themeGet("fontSizes.6")}px;
    }
  }
}
a{
  color:inherit;
  text-decoration:inherit;
  font-size:inherit;
  font-weight:inherit;
}
}
img{
  max-width:100%;
}
.react-parallax-bgimage{
  max-width:unset;
}
.mapboxgl-ctrl-attrib{
  display:none !important;
}
[class*=" icon-"] {
  &:before {margin:0 !important;}
  &.icon-big {font-size:${themeGet("fontSizes.4")}px;}
  &.icon-left {margin-right:${themeGet("space.2")}px;}
}
`;

/* Wiltech wrapper style */
const SiteWrapper = styled.div`
  /* Style system supported prop */
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`;

GlobalStyle.displayName = "GlobalStyle";
SiteWrapper.displayName = "SiteWrapper";

export { GlobalStyle, SiteWrapper };
